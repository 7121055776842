@import '../src/ui/style/colors';

$link-colour: #fff;
$text-colour: #eee;
$footer-dark-grey: #1f1f1f;
$warning-text-colour: #fff;

// The N Shades of Grey

$body-background: #3a3a3a;
$lightest-day-grey: #f0f2f3;
$mid-morning-grey: #e3e4e5;
$murky-grey: #717779;
$pinstripe-grey: #e0e2e5;
$gray-athens: #dfdfdf;
$gray-ship: #4d4f50;
$gray-lavender: #c2c9cb;
$gray-jaguar: #2a2a2a;
$gray-montana: #39393b;
$gray-charcoal: #454545;
$gray-main: #8b9092;
$gray-alabaster: #fafafa;
$label-grey: #585858;
$label-focus-grey: #727272;

// 2016 styleguide
$dark-grey: #24272a;
$text-grey: #3e4553;
$mid-grey: #717779;
$mid-dark-grey: #404344;
$mid-light-grey: #4c4f50;
$secondary-grey: #e2e4e7;
$light-grey: #f2f3f5;
$secondary-nav-grey: darken($murky-grey, 10%);
$blue-grey: #5c6b78;

// Transparent
$transparent-white: rgba(255, 255, 255, 0.13);
$translucent-white: rgba(255, 255, 255, 0.4);

// States

$ui-green: $color-geckoboard-green; // green for ui elements
$visualisation-green: $color-geckoboard-green; // green used in visualisations

$blue-curious: #398fc2;
$orange-beta: #eca245;
$error-red: $color-negative;
$warning-yellow: #e39c4f;

$error-red-rollover: rgb(209, 76, 73);
$ui-green-rollover: rgb(117, 166, 49);

// Charts

$blue-summer-sky: #3fb4f9;
$rag-green: $color-geckoboard-green;

// Buttons

$btn-submit-colour: $link-colour;
$btn-submit-background-colour: $ui-green;
$btn-submit-hover-background-colour: darken($btn-submit-background-colour, 10%);
$btn-secondary-background-colour: $pinstripe-grey;
$btn-secondary-colour: #505050;
$btn-secondary-hover-background-colour: darken(
  $btn-secondary-background-colour,
  10%
);

// Popover
$popover-background-colour: $text-colour;
$popover-header-background-colour: $link-colour;
$popover-footer-background-colour: $pinstripe-grey;

// Form fields
$field-background-colour: $link-colour;
$field-colour: $body-background;
$field-border-colour: $link-colour;
$field-border-focus-colour: darken($text-colour, 10%);
$field-border-colour: $link-colour;
$field-label-background-colour: $gray-athens;
$field-label-focus-background-colour: lighten($gray-athens, 10%);
$field-help-icon-colour: $link-colour;
$field-help-icon-selected-colour: #222222;
$field-disabled-colour: rgb(57, 57, 57);
$field-disabled-background-colour: rgb(176, 176, 176);

// Form fields used on light background
$field-label-background-colour-light: #ddd;
$field-label-focus-background-colour-light: darken($text-colour, 10%);
$field-border-focus-colour-light: darken($text-colour, 10%);

$tooltip-background-colour: #222;
$tooltip-colour: $text-colour;

@mixin variable($property, $variable, $fallback) {
  #{$property}: $fallback;
  #{$property}: var($variable, $fallback);
}
